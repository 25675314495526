<template>
  <div>
    <div v-if="isInStock">
      <q-btn
        v-if="product.minimumAdvertisedPricePolicy == 'Email' && !quoted"
        :label="
          product.style.startsWith('SIG-') ? 'Email For Price' : 'Get Quote'
        "
        :icon="mdiEmailCheckOutline"
        color="secondary"
        class="full-width"
        size="lg"
        square
        @click="quote()"
      />
      <q-btn-group
        v-else
        class="full-width"
        square
      >
        <q-btn
          :icon="cartItem ? mdiCartCheck : mdiCartPlus"
          :color="cartItem ? 'positive' : 'primary'"
          :label="cartItem ? `In Cart (${cartItem.quantity})` : 'Add To Cart'"
          :loading="addToCart.status.value == 'pending'"
          :disable="addToCart.status.value == 'pending'"
          class="full-width"
          size="lg"
          square
          @click="addToCart.execute()"
        />
        <q-btn-dropdown
          :label="quantity"
          :disable="addToCart.status.value == 'pending'"
          :color="cartItem ? 'positive' : 'primary'"
          style="width: 120px"
          size="lg"
          square
        >
          <q-list>
            <q-item
              v-for="n in 5"
              :key="n"
              v-close-popup
              clickable
              @click="quantity = n"
            >
              <q-item-section class="flex-center">
                <q-item-label>{{ n }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-btn-group>
      <div
        v-if="product.quantity && product.quantity <= 10"
        class="text-center text-h6 text-secondary q-pt-sm"
      >
        Only a few left in stock - order soon!
      </div>
    </div>
    <div v-else>
      <q-btn
        v-if="notifiable"
        :icon="mdiBellPlusOutline"
        color="warning"
        class="full-width"
        size="lg"
        label="Get Notified"
        square
        @click="notify()"
      />
      <q-btn
        v-else
        color="negative"
        class="full-width"
        size="lg"
        label="Out Of Stock"
        flat
        square
        disable
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  mdiBellPlusOutline,
  mdiCartCheck,
  mdiCartPlus,
  mdiEmailCheckOutline,
} from '@quasar/extras/mdi-v7';
import { uid } from 'quasar';

const cartStore = useCartStore();

const { product, variation, dimensions, quantity, add, notify, quote, quoted } = useProduct()!;

const isInStock = computed(
  () => variation.value?.isInStock ?? product.isInStock,
);

const notifiable = computed(
  () =>
    !product.isInStock
    && !dimensions.value.length
    && product.orderType == 'Notify',
);

const addToCart = useAsyncData(uid(), () => add(), {
  immediate: false,
});

const cartItem = computed(() =>
  cartStore.data?.items.find(
    i => i.productId == product.id && i.variationId == variation.value?.id,
  ),
);
</script>
