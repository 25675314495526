<template>
  <div class="row">
    <q-item
      v-for="location in availability"
      :key="location.locationId"
      :href="locationUrl(location.locationId)"
      clickable
      class="col-12 col-md-6"
      target="_blank"
    >
      <q-item-section avatar>
        <q-avatar
          :icon="location.isAvailable ? mdiCheck : mdiClose"
          :color="location.isAvailable ? 'positive' : 'negative'"
          size="xl"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-h6 text-weight-medium">
          {{ location.locationName }}
        </q-item-label>
        <q-item-label class="text-body2">
          {{ location.locationAddress.street }},
          {{ location.locationAddress.city }}
          {{ location.locationAddress.state }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </div>
</template>

<script lang="ts" setup>
import { mdiCheck, mdiClose } from '@quasar/extras/mdi-v7';

const { product, variation } = useProduct()!;
const { locationUrl } = useBusiness();

const availability = computed(
  () => variation.value?.inStoreAvailability ?? product.inStoreAvailability,
);
</script>
