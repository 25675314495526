<template>
  <section>
    <q-card
      flat
      square
    >
      <div class="q-px-sm q-py-md">
        <div class="row">
          <div
            v-for="item in items"
            :key="item.label"
            class="col-12 col-sm-6 col-md-4 col-lg-3"
          >
            <q-item :to="item.to">
              <q-item-section class="col-6 col-md-4">
                <q-item-label class="text-subtitle2 text-grey-5 bg-grey-9 q-pa-sm ellipsis">
                  {{ item.label }}
                </q-item-label>
              </q-item-section>
              <q-item-section class="col-6 col-md-8">
                <q-item-label
                  class="text-subtitle2 text-weight-medium q-pl-sm"
                  :class="{ 'text-link': item.to }"
                >
                  {{ item.value }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </div>
      </div>
    </q-card>
  </section>
</template>

<script lang="ts" setup>
interface Item {
  label: string;
  value: string;
  to?: string;
}

const { product } = useProduct()!;

const items: Item[] = [
  { label: 'Item #', value: product.id },
  { label: 'Style', value: product.style },
];

if (product.upc) {
  items.push({ label: 'UPC', value: product.upc });
}

if (product.manufacturer) {
  items.push({
    label: 'Manufacturer',
    value: product.manufacturer.name,
    to: `/brand/${product.manufacturer.slug}`,
  });
}

product.attributes.forEach(({ label, value }) => items.push({ label, value }));
</script>
