<template>
  <q-page v-if="data">
    <PageBreadcrumbs :items="breadcrumbs" />
    <section>
      <div class="row">
        <div class="col-12 col-md-5">
          <ProductGallery />
        </div>

        <div class="col-12 col-md-7">
          <div class="q-pa-md">
            <h1 class="text-h3 q-mt-none text-weight-medium q-mb-sm">
              {{ variation?.name ?? product.shortDescription ?? product.name }}
            </h1>

            <div
              v-if="product.longDescription"
              class="text-subtitle2 text-grey-5 q-mt-none text-weight-medium q-mb-sm"
            >
              {{ product.longDescription }}
            </div>

            <div class="row items-center q-gutter-md q-mb-sm">
              <div
                v-if="product.reviewCount"
                class="row items-center "
              >
                <div class="text-body1">
                  {{ rating(product.averageScore) }}
                </div>
                <q-rating
                  :model-value="product.averageScore"
                  :icon-half="mdiStarHalfFull"
                  max="5"
                  size="xs"
                  color="secondary"
                  class="q-px-sm"
                  readonly
                />
                <nuxt-link
                  class="text-link text-primary text-body1 cursor-pointer"
                  to="#reviews"
                >
                  {{ pluralize(product.reviewCount, 'review') }}
                </nuxt-link>
              </div>

              <div>
                <q-btn
                  :icon="mdiShareVariant"
                  label="Share"
                  color="secondary"
                  outline
                  square
                  @click="shareDialog()"
                />
              </div>
            </div>

            <div class="row items-center q-col-gutter-y-md q-mb-md">
              <div class="col-12 col-sm-6">
                <ProductPrice />
              </div>
              <div
                v-if="!product.isClass3 && $credova.valid(product.price)"
                class="col-12 col-sm-6"
              >
                <LazyProductFinancing />
              </div>
            </div>

            <div
              v-if="options.length"
              class="q-mb-lg"
            >
              <div
                v-for="(option, index) in options"
                :key="option.label"
                class="q-mb-sm"
              >
                <div class="text-h6 text-weight-light">
                  {{ option.label }}
                </div>

                <div class="row q-gutter-md">
                  <q-btn
                    v-for="item in option.items"
                    :key="item"
                    :label="item"
                    :color="dimCodes[index] === item ? 'primary' : 'white'"
                    size="md"
                    outline
                    square
                    @click="dimCodes.splice(index, 1, item)"
                  />
                </div>
              </div>
            </div>

            <q-tabs
              v-model="availabilityTab"
              class="text-grey"
              active-color="primary"
              indicator-color="primary"
              align="justify"
              narrow-indicator
              dense
            >
              <q-tab
                v-if="!product.isInStore"
                name="online"
                label="Online"
                :content-class="!product.isInStock ? 'text-strike' : ''"
              />
              <q-tab
                name="instore"
                label="In Store"
                :content-class="!availableInStore ? 'text-strike' : ''"
              />
            </q-tabs>

            <q-tab-panels
              v-model="availabilityTab"
              animated
              swipeable
              keep-alive
            >
              <q-tab-panel
                v-if="!product.isInStore"
                name="online"
                class="no-scroll"
              >
                <ProductAvailabilityOnline />
              </q-tab-panel>

              <q-tab-panel
                name="instore"
                class="no-scroll"
              >
                <ProductAvailabilityStore />
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </div>
      </div>
    </section>

    <ProductData />

    <ProductDescription class="q-pa-md" />

    <LazyProductVideos
      v-if="product.videoCount"
      class="q-pa-md"
    />

    <LazyCatalogCarousel
      v-if="product.related.filter(r => r.type == 'XSell').length"
      :data-key="`products_${product.id}_related`"
      :query="{
        id: { $in: product.related.filter(r => r.type == 'XSell').map(r => r.id) },
      }"
      :size="product.related.length"
      title="Frequently Bought Together"
      name="Related Cross-sell"
      class="q-pa-md"
    />

    <LazyProductReviews
      v-if="product.reviewCount"
      id="reviews"
      ref="reviewsRef"
      class="q-pa-md"
    />

    <LazyCatalogCarousel
      v-if="product.isFirearm && attributes.calibers.value.length"
      :data-key="`products_${product.id}_ammunition_xsell`"
      :query="{
        isAmmunition: { $eq: true },
        isInStock: { $eq: true },
        caliber: { $in: attributes.calibers.value },
      }"
      :size="24"
      title="Don't Forget Your Ammo"
      name="Ammunition Cross-sell"
      class="q-pa-md"
    />

    <LazyCatalogCarousel
      v-else-if="product.isAmmunition && attributes.calibers.value.length"
      :data-key="`products_${product.id}_firearm_xsell`"
      :query="{
        isFirearm: { $eq: true },
        isInStock: { $eq: true },
        caliber: { $in: attributes.calibers.value },
      }"
      :size="24"
      :title="`Shop ${attributes.calibers.value.join('/')} Firearms`"
      name="Firearm Cross-sell"
      class="q-pa-md"
    />

    <LazyCatalogCarousel
      v-else-if="product.manufacturer"
      :data-key="`products_${product.id}_manufacturer_xsell`"
      :query="{
        manufacturer: { id: { $eq: product.manufacturer.id } },
        id: { $ne: product.id },
      }"
      :size="24"
      :title="`More from ${product.manufacturer.name}`"
      name="Manufacturer Cross-sell"
      class="q-pa-md"
    />
  </q-page>
</template>

<script lang="ts" setup>
import { mdiShareVariant, mdiStarHalfFull } from '@quasar/extras/mdi-v7';
import type { ProductResponse } from '~/types/ecommerce';

definePageMeta({
  alias: '/p/:slug',
});

const { $bus, $credova, $ecommerce } = useNuxtApp();
const route = useRoute();
const { pluralize, rating } = useFormatting();
const { shareDialog } = useDialogs();
const reviewsRef = ref<HTMLElement | null>(null);

const { data, error } = await useAsyncData(
  `products_${route.params.slug}`,
  () => $ecommerce.fetch<ProductResponse>(`products/${route.params.slug}`),
);

if (error.value) {
  throw createError({ ...error.value, fatal: true });
}

const product = data.value as ProductResponse;

const { options, dimCodes, variation, attributes } = useProvideProduct(product);

useSeoMeta({
  title: () => (product.metaTitle ?? product.name).replaceAll('"', 'ʺ'),
  description: () => product.metaDescription,
});

// Always make sure the canonical URL set correctly
useHead({ link: [{ rel: 'canonical', href: withSiteUrl(`/product/${product.slug}`) }] });

defineOgImage(
  product.thumbnailUrl
    ? {
        url: product.thumbnailUrl,
        width: 500,
        height: 500,
      }
    : undefined,
);

useSchemaOrg([defineSchemaOrgProduct(product)]);

const availableInStore = computed(() => product.inStoreAvailability.some(a => a.isAvailable));

const availabilityTab = ref(product.isInStore || (!product.isInStock && availableInStore.value) ? 'instore' : 'online');

const breadcrumbs = computed(() => {
  const stack = product.manufacturer
    ? [
        { label: 'Brands', to: '/brands' },
        {
          label: product.manufacturer.name,
          to: `/brand/${product.manufacturer.slug}`,
        },
      ]
    : [];

  return [...stack, { label: product.style }];
});

$bus.emit('product:view', product);
</script>
