<!-- eslint-disable vue/no-v-html -->
<template>
  <section v-intersection.once="onIntersection">
    <div v-if="description?.value">
      <div
        class="text-content"
        v-html="description.value"
      />
    </div>
    <div v-else-if="status == 'idle' || status == 'pending'">
      <q-skeleton
        v-for="line in 12"
        :key="line"
        type="text"
      />
    </div>
    <div
      v-else
      class="text-content"
    >
      Shop with KYGUNCO for the best selection, competitive pricing, and exceptional
      customer service. Experience the difference that comes with buying from a trusted
      firearms retailer with over 20 years of experience in the industry.
    </div>
  </section>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    type: String,
  },
});

const { product } = useProduct()!;

const { $ecommerce } = useNuxtApp();

const { data: description, execute, error, status } = await useAsyncData(
  `products_${product.id}_description`,
  () => $ecommerce.fetch<{ value: string }>(`/products/${product.id}/description`),
  {
    lazy: true,
    immediate: import.meta.server,
    getCachedData: getCachedPayload,
  },
);

const onIntersection = (entry: IntersectionObserverEntry) => {
  if (!entry.isIntersecting) {
    return true;
  }

  if (description.value || error.value) {
    return false;
  }

  execute();
  return false;
};
</script>
